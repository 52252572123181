import React, { Component } from 'react'
import styled from 'styled-components'

// import background from '../../../public/images/guy.png' // can't import from outside src with create-react-app
// import background from './guy.png'  // -> ${background}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	/* justify-content: center; */

	/* https://stackoverflow.com/questions/44114436/the-create-react-app-imports-restriction-outside-of-src-directory */
	background-image: url(${process.env.PUBLIC_URL + '/images/guy.png'});
	/* width: 100vh; */
	height: 100vh;
	background-color: black;

	/* Position and scale the image */
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100vh;
`

const ContentLayer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	/* background-color: red;
	opacity: 0.5; */
`

const ImageLayer = styled.div`
	/* width: 600px; */
	width: 28%;
	/* background-color: black; */
	/* opacity: 0.5; */
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;
	/* background-color: yellow;
	opacity: 0.5; */
`

const Text = styled.div`
	color: white;
`

const Title = styled(Text)`
	margin-bottom: 5em;
`

const SubTitle = styled(Text)`
	text-align: left;
	margin-bottom: 1em;
`

class Home extends Component {

	render() {
		return(
			<Container>

				<ContentLayer>

					<Column>
						<Title>
							Valentin Cure
						</Title>
						<SubTitle>
							This is my website
						</SubTitle>
						<SubTitle>
							And I'm reworking it :)
						</SubTitle>
					</Column>
					
				</ContentLayer>

				<ImageLayer />				

			</Container>
		)
	}
}

export default Home